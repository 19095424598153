<script>
import GlobalVue from '@root/helper/Global.vue';
import BoConfig from '@root/config/BoConfig';

var $ = window.$

export default {
    name: "SideBar",
    extends: GlobalVue,
    async mounted(){
        await this.ready();
        this.initMenu()
    },
    computed:{
        menus(){
            return BoConfig.sidebar
        }
    },
    methods:{
        logout(){
            this.page.logout()
        },
        async initMenu(){
            setTimeout(() => { 
                var $ = window.$
                var url = window.location.href;
                var element = $('ul#sidebarnav a').filter(function () {
                    return this.href == url;
                }).addClass('active').parent().addClass('active');
                while (true) {
                    if (element.is('li'))
                        element = element.parent().addClass('in').parent().addClass('active').children('a').addClass('active');
                    else 
                        break;
                }
                $(document).on('click','#sidebarnav a', function (e) {
                    e.preventDefault();
                    $("ul", $(this).parents("ul:first")).removeClass("in");
                    $("a", $(this).parents("ul:first")).removeClass("active");
                    $("li", $(this).parents("ul:first")).removeClass("active");  
                    if ($(this).hasClass("has-arrow")) {
                        $(this).next("ul").addClass("in");
                        $(this).addClass("active");
                    }
                })
                $('.scroll-sidebar, .right-side-panel, .message-center, .right-sidebar').perfectScrollbar();
            }, 50);
        },
        haveRoute(link){
            let l = this.$router.resolve({name: link});
            if( l.resolved.matched.length > 0) return true
            return false
        },
        haveTargetBlank(link){
            if(link.indexOf("http")==-1) return false
            return link.indexOf(location.host)>-1 ? false : "_blank"
        },
    },
    watch:{
        '$route.path'(){ this.initMenu() }
    }
};
</script>
<template>
<aside class="left-sidebar">
    <div class="scroll-sidebar">
        <div class="user-profile">
            <div class="user-pro-body">
                <div><VImg :src="assets('bo_images','user.jpg')" :lazy-src="uploader(user.bu_pic,'50x50c')" alt="user-img"  class="img-circle"></VImg></div>
                <div class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle u-dropdown link hide-menu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Administrator<span class="caret"></span></a>
                    <div class="dropdown-menu animated flipInY">
                        <div class="dropdown-divider"></div>
                        <router-link :to="{name:'BoProfile'}" class="dropdown-item"><i class="ti-settings"></i> Account Setting</router-link>
                        <div class="dropdown-divider"></div>
                        <a href="javascript:;" @click="logout()" class="dropdown-item"><i class="fa fa-power-off"></i> Logout</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <template v-for="(v,k) in menus">
                <li v-if="v.divider"  class="nav-small-cap" :key="k">--- {{v.name}}</li>
                <li v-else-if="v.childs" :key="k">
                    <a href="javascript:;" class="has-arrow waves-effect waves-dark">
                        <i :class="v.icon"></i>
                        <span class="hide-menu">{{v.name}}</span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <template v-for="(v2,k2) in v.childs">
                        <li v-if="page.menuRole(v2.link)" :key="k2">
                            <router-link v-if="haveRoute(v2.link)" :to="{name: v2.link}">{{v2.name}}</router-link>
                            <a v-else-if="v2.link.indexOf('://')>-1" :href="v2.link" :target="haveTargetBlank(v2.link)">{{v2.name}}</a>
                        </li>
                        </template>
                    </ul>
                </li>
                <li v-else :key="k">
                    <router-link v-if="page.menuRole(v.link)&&haveRoute(v.link)" :to="{name:v.link}" class="waves-effect waves-dark">
                    <i :class="v.icon"></i> <span class="hide-menu">{{v.name}} </span>
                    </router-link>
                    <a v-else-if="v.link.indexOf('://')>-1" :href="v.link" :target="haveTargetBlank(v.link)"><i :class="v.icon"></i> <span class="hide-menu">{{v.name}} </span></a>
                </li>
                </template>
                <li class="nav-small-cap">--- SUPPORT</li>
                <li> <a class="waves-effect waves-dark" href="javascript:;" aria-expanded="false" @click="logout()"><i class="far fa-circle text-danger"></i><span class="hide-menu">Log Out</span></a></li>
            </ul>
        </nav>
    </div>
</aside>
</template>